<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-calculator">
          <v-toolbar flat>
            <v-autocomplete v-model="selectSKPD" :items="skpd" @change="cekUnitKerja" label="SKPD" item-text="v" item-value="k" hide-details></v-autocomplete>
            <!-- <v-select label="Pilih SKPD" hide-details></v-select> -->
            <v-spacer></v-spacer>
            <!-- <v-select label="Pilih Unit Kerja" hide-details></v-select> -->
            <v-autocomplete v-model="selectUnitKerja" :items="unitKerja" :loading="loadingUnitKerja" label="Pilih Unit Kerja" item-text="v" item-value="k" hide-details></v-autocomplete>
          </v-toolbar>
          <div class="ma-5">
            <h2>Pilih Bulan</h2>
            <table>
              <tr v-for="(month, i) in months" :key="i">
                <td style="width:150px">{{month}}</td>
                <td><v-btn @click="checkPerhitunganKinerja(month,i)" color="blue" class="ma-1" dark>Tabel Capaian</v-btn></td>
                <td><v-btn color="blue" class="ma-1" dark>Capaian Kolektif</v-btn></td>
                <td><v-btn color="green" class="ma-1" dark><v-icon>mdi-file-excel</v-icon> Export Excel</v-btn></td>
                <!-- <td><v-btn color="green" class="ma-1" dark><v-icon>mdi-file-excel</v-icon> Export All SKPD</v-btn></td> -->
              </tr>
            </table>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Loading from '@/components/base/Loading'
import store from '@/store'
import axios from 'axios'
import _ from 'lodash'
import _g from '../../global'
import {local} from '@/store/local'
export default {
  data(){
    return {
      months:['Januari', 'Febuari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
      selectSKPD:'',
      skpd:[],
      selectUnitKerja:'',
      unitKerja:[],
      loadingUnitKerja:false
    }
  },
  created() {
    this.getListSKPD()
  },
  methods: {
    getListSKPD(){
      var url = "v1/get-data-skpd-satuan-kerja"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },
    getListSatuanKerja(val){
      this.loadingUnitKerja = true
      var url = "v1/get-unit-kerja"
      let current = store.state.user.current
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {satuan_kerja_id:val, api:1}).then(response => {
        let data = []
        _.each(response.data, function(value, key, obj){
          data.push({k:value.unit_kerja_id, v: value.unit_kerja_nama})
        })
        this.unitKerja = data
        this.loadingUnitKerja = false
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },
    cekUnitKerja(){
      this.getListSatuanKerja(this.selectSKPD)
    },
    checkPerhitunganKinerja(m,i){
      var data = {}
      data.satuan_kerja_id = this.selectSKPD
      data.unit_kerja_id = this.selectUnitKerja
      local.setLocal("pk_skpd", data)
      this.$router.push({
        path: '/admin/perhitungan-kinerja/capaian',
        component:() => import('@/views/PerhitunganKinerja/CapaianKinerja.vue'),
        params: {'data': JSON.stringify(data)}
      })
    },
  },
}
</script>

<style lang="css" scoped>
</style>
